.content {

    h2 {
        font-size: 1.42857143rem;
        font-weight: 400;
        margin-bottom: 20px;
    }

    &__main {
        float: left;
        width: 640px;
        padding: 0 20px 0 40px;
        border-right: 1px solid #D8D8D8;

        &-full {
            float: none;
            width: 100%;
            padding: 0 40px;
            border: 0;
        }
    }

    &__sidebar {
        float: right;
        width: 335px;
        padding: 0 40px 0 20px;
    }

    &__text {
        font-weight: 200;
        line-height: 1.42857143;
    }

    &__cooperation {

        .content__text {
            width: 50%;
            float: right;
        }

        .form {
            width: 50%;
            float: left;
            padding: 0 20px 0 0;
        }
    }
}