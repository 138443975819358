.news {

    &__one {
        margin-left: -20px;
        margin-right: -20px;
        margin-bottom: 20px;
        padding: 0 20px 20px 20px;
        border-bottom: 1px solid #D8D8D8;
    }

    &__title {
        margin-bottom: 10px;
        font-size: 1.14285714rem;
        font-weight: 400;
        line-height: 1.42857143rem;

        a {
            text-decoration: none;
        }
    }

    &__date {
        display: block;
        margin-bottom: 20px;
        font-size: 0.85714286rem;
        color: #9B9B9B;
    }

    &__content {
        font-weight: 200;
        line-height: 1.42857143;
    }
}