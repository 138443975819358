// Variables
@color1: #0D3862;
@color2: #006A9C;

// Fonts

@font-face {
    font-family: 'Conqueror';
    src: url('/static/fonts/ConquerorSans-Light-webfont.eot');
    src: url('/static/fonts/ConquerorSans-Light-webfont.eot?#iefix') format('embedded-opentype'),
         url('/static/fonts/ConquerorSans-Light-webfont.woff2') format('woff2'),
         url('/static/fonts/ConquerorSans-Light-webfont.woff') format('woff'),
         url('/static/fonts/ConquerorSans-Light-webfont.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Conqueror';
    src: url('/static/fonts/ConquerorSans-webfont.eot');
    src: url('/static/fonts/ConquerorSans-webfont.eot?#iefix') format('embedded-opentype'),
         url('/static/fonts/ConquerorSans-webfont.woff2') format('woff2'),
         url('/static/fonts/ConquerorSans-webfont.woff') format('woff'),
         url('/static/fonts/ConquerorSans-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

.transition (@transition) {
    -webkit-transition: @transition;  
    -moz-transition:    @transition;
    -ms-transition:     @transition; 
    -o-transition:      @transition;  
}


html, body {
    background: #f4f4f4;
    color: #1D1D1E;
    box-sizing: border-box;
    font-size: 14px;
    line-height: 1.28571429;
    font-family: 'Conqueror', Arial, sans-serif;
}

*, *:before, *:after {
  box-sizing: inherit;
}

.container {
    width: 980px;
    margin: 0 auto;
    background: #fff;
    border-left: 5px solid @color1;
}

section:after {
    content: "";
    display: table;
    clear: both;
}

a {
    color: @color2;
    transition: all .2s ease;
}

hr.more {
    display: none;
}

p {
    margin-bottom: 1em;
}

table {
    border-collapse: collapse;
    display: table;
    width: 100%;
    max-width: 100%;

    &.table-striped > tbody > tr:nth-of-type(odd) {
        background: #f4f4f4;
    }

    td {
        border-collapse: collapse;
        border-top-color: rgb(221, 221, 221);
        border-top-style: solid;
        border-top-width: 1px;
        display: table-cell;
        padding-bottom: 8px;
        padding-left: 8px;
        padding-right: 8px;
        padding-top: 8px;
        vertical-align: top;
    }
}

h1 {
    margin-bottom: 1em;
}