.header {
    height: 120px;
    margin: 0 40px;

    &__logo {
        display: block;
        float: left;
        width: 424px;
        height: 120px;
        margin-left: -40px;

         img {
            width: 424px;
            height: 120px;
        }
    }

    &__contacts {
        float: right;
        padding-top: 30px;
        text-align: right;
        color: #4A4A4A;
    }

    &__phone {
        display: block;
        font-size: 1.42857143rem;
        color: #000000;
    }
}