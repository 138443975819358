.menu {
    height: 50px;
    margin-bottom: 30px;
    padding: 0 38px;
    line-height: 50px;
    background: @color1;
    font-size: 1.07142857rem;

    a {
        display: block;
        float: left;
        margin-left: -1px;
        padding: 0 22px;
        text-decoration: none;
        color: #fff;
        border-left: 1px solid rgba(255,255,255,.1);
        border-right: 1px solid rgba(255,255,255,.1);

        &:hover, &.active {
            background: @color2;
        }

        &.menu__locale {
            float: right;
            padding: 0 15px;
        }
    }
}