/*
    Frameless           <http://framelessgrid.com/>
    by Joni Korpi       <http://jonikorpi.com/>
    licensed under CC0  <http://creativecommons.org/publicdomain/zero/1.0/>
*/


//
// Configuration
//

@font-size: 14;         // Your base font-size in pixels
@em: @font-size*1em;    // Shorthand for outputting ems, e.g. "12/@em"

@column: 60;    // The column-width of your grid in pixels
@gutter: 20;    // The gutter-width of your grid in pixels


//
// Column-widths in variables, in ems
//

 @1cols: ( 1 * (@column + @gutter) - @gutter) / @em; @1col: @1cols;
 @2cols: ( 2 * (@column + @gutter) - @gutter) / @em;
 @3cols: ( 3 * (@column + @gutter) - @gutter) / @em;
 @4cols: ( 4 * (@column + @gutter) - @gutter) / @em;
 @5cols: ( 5 * (@column + @gutter) - @gutter) / @em;
 @6cols: ( 6 * (@column + @gutter) - @gutter) / @em;
 @7cols: ( 7 * (@column + @gutter) - @gutter) / @em;
 @8cols: ( 8 * (@column + @gutter) - @gutter) / @em;
 @9cols: ( 9 * (@column + @gutter) - @gutter) / @em;
@10cols: (10 * (@column + @gutter) - @gutter) / @em;
@11cols: (11 * (@column + @gutter) - @gutter) / @em;
@12cols: (12 * (@column + @gutter) - @gutter) / @em;


//
// Column-widths in a function, in ems
//

.width (@cols:1) {
    width: (@cols * (@column + @gutter) - @gutter) / @em;
}


/*
    Margin, padding, and border resets
    except for form elements
*/

body, div,
h1, h2, h3, h4, h5, h6,
p, blockquote, pre, dl, dt, dd, ol, ul, li,
fieldset, form, label, legend, th, td,
article, aside, figure, footer, header, hgroup, menu, nav, section {
    margin: 0;
    padding: 0;
    border: 0;
}


/*
    Consistency fixes
    adopted from http://necolas.github.com/normalize.css/
*/

article, aside, details, figcaption, figure, 
footer, header, hgroup, nav, section, audio, canvas, video {
    display: block;
}

html {
    height: 100%; 
    -webkit-text-size-adjust: 100%; 
    -ms-text-size-adjust: 100%;
}

    body {min-height: 100%; font-size: 100%;}

sub, sup {
    font-size: 75%; 
    line-height: 0; 
    position: relative; 
    vertical-align: baseline;
}

    sup {top: -0.5em;}
    sub {bottom: -0.25em;}

pre {
    white-space: pre; 
    white-space: pre-wrap; 
    word-wrap: break-word;
}

b, strong {font-weight: bold;}
abbr[title] {border-bottom: 1px dotted;}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

a img, img {
    -ms-interpolation-mode: bicubic;
    border: 0;
}

input, textarea, button, select {
    margin: 0;
    font-size: 100%;
    line-height: normal;
    vertical-align: baseline;
}

    button,
    html input[type="button"], 
    input[type="reset"], 
    input[type="submit"] {
        cursor: pointer;
        -webkit-appearance: button;
    }
    
    input[type="checkbox"],
    input[type="radio"] {
        -webkit-box-sizing: border-box;
           -moz-box-sizing: border-box;
             -o-box-sizing: border-box;
            -ms-box-sizing: border-box;
                box-sizing: border-box;
    }
    
    textarea {overflow: auto;}


/*
    These are easy to forget
*/

::selection {
    background: red;
    color: white;
}
::-moz-selection {
    background: red;
    color: white;
}

img::selection {
    background: transparent;
}
img::-moz-selection {
    background: transparent;
}

body {
    -webkit-tap-highlight-color: rgba(255,0,0, 0.62);
}


/*
    Style overrides for IE6-8
    http://jonikorpi.com/leaving-old-IE-behind/
*/

.ie {
    
}


/*
    An easy way to zoom your entire layout in or out (as long as it's set in ems).
    Just change the media queries to activate them.
    Assuming your base font-size is 16:
    - the first one zooms out by a factor of (16-2)/16 = 0.875
    - the second one zooms in by a factor of (16+2)/16 = 1.125  
*/

@media screen and (max-width: 1px) {
    body {
        font-size: (@font-size - 2)/16*1em;
    }
}

@media screen and (max-width: 1px) {
    body {
        font-size: (@font-size + 2)/16*1em;
    }
}