.button {
    display: inline-block;
    padding: 11px 17px;
    border: 1px solid @color2;
    font-weight: 200;
    text-decoration: none;

    &:hover,
    &:focus {
        background: @color2;
        color: #fff;
    }
}