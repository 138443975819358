.form {
    color: #1e1e1e;
    font-size: 1rem;
    line-height: 1.2em;

    .form__answer {
        margin-top: 10%;
        padding: 0 50px;
        line-height: 1.2em;
    }

    .group {
        position: relative;
        margin-bottom: 10px;

        label {
            display: block;
        }

        input {
            width: 100%;
            border: 1px solid #D8D8D8;
            padding: 10px 13px;
            height: 38px;
            background: rgba(255, 255, 255, .23);
            .transition(all .2s ease);
            font-family: inherit;
            font-size: 1rem;
            color: #1e1e1e;

            &:focus {
                border: 1px solid @color2;
                outline: 0;
            }
        }

        textarea {
            width: 100%;
            border: 1px solid #D8D8D8;
            padding: 13px;
            background: rgba(255, 255, 255, .23);
            .transition(all .2s ease);
            font-family: inherit;
            font-size: 1rem;
            color: #1e1e1e;
            resize: none;

            &:focus {
                border: 1px solid @color2;
                outline: 0;
            }
        }

        &.error {
            color: #861419;

            input, textarea {
                border: 1px solid #861419;
            }
        }
    }

    .controls {

        input[type="submit"] {
            float: left;
            display: inline-block;
            margin-right: 20px;
            padding: 11px 17px;
            background: #fff;
            border: 1px solid @color2;
            color: @color2;
            font-weight: 200;
            text-decoration: none;
            cursor: pointer;
            .transition(all .2s ease);

            &:hover,
            &:focus {
                background: @color2;
                color: #fff;
            }
        }

        .warning {
            margin: 0;
            padding-top: 3px;
            color: #4A4A4A;
        }
    }
}