.photos {

    &__list {
    }

    &__mini {
        text-decoration: none;
    }


    &__item {
        float: left;
        display: inline-block;
        margin: 0 5px 5px 0;
        font-size: 0;

        &:nth-child(4n) {
            margin-right: 0;
        }
    }
}