.airship {
    position: relative;

    & > ul {
        background: #fff;
        width: 240px;
        float: left;
        margin: 0;
        list-style-type: none;
        
        li {
            border-bottom: 1px solid #D8D8D8;
            padding: 15px;
            font-size: 11px;
            cursor: pointer;
            
            span {
                font-size: 14px;
            }
            
            &:nth-child(1) {
                border-top: 1px solid #D8D8D8;
            }
            
            &:hover {
                background: #f2f2f2;
            }
            
            &.active {
                background: @color1;
                color: #fff;
            }
        }
    }

    .content {
        width: 655px;
        float: right;
        padding: 15px;
        background: #fff;
        border-top: 1px solid #D8D8D8;
        border-left: 1px solid #D8D8D8;

        .description {
            width: 364px;
            float: right;

            p {
                font-weight: 200;
                line-height: 1.42857143;
            }
        }
        
        div.tech_content {
            clear: both;
            h3 { border-top: 1px solid #ccc; padding: 10px 0;} 
        }
    }
}

.airship_photo {
    width: 250px;
    float: left;
    margin-right: 10px;
    
    img {
        float: left;
        margin-right: 5px;
        margin-bottom: 5px;
        opacity: 0.8;
        
        &:hover {
            opacity: 1;
        }
    }
}