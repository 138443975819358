.footer {
    margin: 70px 20px 0;
    padding: 20px 20px;
    border-top: 1px solid #979797;

    &__contacts {
        float: left;
        text-align: left;
        color: #4A4A4A;
    }

    &__phone {
        display: block;
        font-size: 1.42857143rem;
        color: #000000;
    }

    &__il-studio {
        float: right;
        font-size: 0.85714286rem;
        font-weight: 200;

        a:nth-child(1) {
            color: #4A4A4A;
            text-decoration: none;
        }
    }
}